<template>
  <div id="app">
    <router-view class="router-content" />
  </div>
</template>
<script>
export default {
  name: "app"
};
</script>
<style >
body {
  font-family: "Verdana";
  background-color: #ffffff !important;
}

#ismobile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, .8);
  padding: 2rem;
  z-index: 100000;
  top: 1px;
  text-align: center;
  display: none;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  #ismobile {
    display: flex;
    width: 100vw;
    height: 100vh;
  }
}
</style>
