import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/home",
      name: "home",
      component: () => import("./components/Home")
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./components/Login")
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("./components/Profile")
    },
    {
      path: "/Feature-Enhancement",
      name: "HelpPDF",
      component: () => import("./components/HelpPDF")
    },
    {
      path: "/manage-communications",
      redirect: {name: 'profile'}
    },
  ]
});